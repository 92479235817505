/* BORDER-RADIUS */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: '';
  @if ($breakpoint_clave != '') {
    $modificador_responsive: '\\@#{$breakpoint_clave}';
  }
  @media (min-width: $breakpoint_valor) {
    @each $clave_radius, $valor_radius in $border-radiusLista {
      .g-border-radius-#{$clave_radius}#{$modificador_responsive} {
        border-radius: $valor_radius;
      }
      .g-border-top-left-radius-#{$clave_radius}#{$modificador_responsive} {
        border-top-left-radius: $valor_radius;
      }
      .g-border-top-right-radius-#{$clave_radius}#{$modificador_responsive} {
        border-top-right-radius: $valor_radius;
        
      }
      .g-border-bottom-left-radius-#{$clave_radius}#{$modificador_responsive} {
        border-bottom-left-radius: $valor_radius;
      }
      .g-border-bottom-right-radius-#{$clave_radius}#{$modificador_responsive} {
        border-bottom-right-radius: $valor_radius;
      }
    }
  }
}
