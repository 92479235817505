$prefijo: '--';
@function custom-property-name($name, $base) {
  @return $prefijo + $base + $name;
}

:root {
  @each $clave_color, $valor_color in $colores-bordeLista {
    #{custom-property-name($clave_color, "bordeLista-")}: #{$valor_color};
  }

  @each $componente, $variantes_color in $colores-backgroundLista {
    @each $clave_color, $valor_color in $variantes_color {
        #{custom-property-name($componente + "-" + $clave_color, "backgroundLista-")}: #{$valor_color};
    }
  }

  @each $clave_color, $valor_color in $colores-textoLista {        
    #{custom-property-name($clave_color, "textoLista-")}: #{$valor_color};
  }
}
