/* BORDER-COLOR */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: '';
  @if ($breakpoint_clave != '') {
    $modificador_responsive: '\\@#{$breakpoint_clave}';
  }
  @media (min-width: $breakpoint_valor) {
    .g-border-color-transparent#{$modificador_responsive} {
      border-color: transparent;
    }
    @each $clave_color, $valor_color in $colores-bordeLista {
      .g-border-color-#{$clave_color}#{$modificador_responsive} {
        border-color: $valor_color;
      }
    }
  }
}
