/* OPACITY */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
    $modificador_responsive: '';
    @if ($breakpoint_clave != '') {
      $modificador_responsive: '\\@#{$breakpoint_clave}';
    }
    @media (min-width: $breakpoint_valor) {
      @each $opacity_clave, $opacity_valor in $opacityLista {
        .g-opacity-#{$opacity_clave}#{$modificador_responsive} {
          opacity: $opacity_valor;
        }
      }
    }
  }