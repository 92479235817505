/* COLOR, DE TEXTO */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
    $modificador_responsive: '';
    @if ($breakpoint_clave != '') {
      $modificador_responsive: '\\@#{$breakpoint_clave}';
    }
    @media (min-width: $breakpoint_valor) {
      .g-color-transparent#{$modificador_responsive} {
        color: transparent;
      }
      @each $clave_color, $valor_color in $colores-textoLista {        
          .g-color-#{$clave_color}#{$modificador_responsive} {
            color: $valor_color;
          }
        }
      }
    }
  
