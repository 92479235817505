@import '../utilities/mixins';
/* BOX-SHADOW */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: '';
  @if ($breakpoint_clave != '') {
    $modificador_responsive: '\\@#{$breakpoint_clave}';
  }
  @media (min-width: $breakpoint_valor) {
    @each $sombra_clave, $sombra_valores in $sombrasLista {
        .g-sombra-#{$sombra_clave}#{$modificador_responsive} {
          @include box-shadow($sombra_clave);
        }      
    }
  }
}
