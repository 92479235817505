@import "@fontsource/rubik"; // defaults to weight 400, Rubik has no 100 and 200 weight
@import "@fontsource/rubik/300.css";
@import "@fontsource/rubik/400.css";
@import "@fontsource/rubik/600.css";
@import "@fontsource/rubik/800.css";
@import "@fontsource/rubik/300-italic.css";
@import "@fontsource/rubik/400-italic.css";
@import "@fontsource/rubik/600-italic.css";
@import "@fontsource/rubik/800-italic.css";
@import '@fontsource/kumbh-sans/200.css';
@import '@fontsource/kumbh-sans/300.css';
@import '@fontsource/kumbh-sans/400.css';
@import '@fontsource/kumbh-sans/500.css';
@import '@fontsource/kumbh-sans/600.css';
@import '@fontsource/kumbh-sans/700.css';
@import "../../../libs/shared/utilities/variables";
@import "../../../libs/shared/utilities/functions";
@import "../../../libs/shared/utilities/mixins";
@import "../../../libs/shared/utilities/myPopups";
@import "../../../libs/shared/utilities/exportarVariables";
@import "../../../libs/shared/global/badge";

/*Estilos mapa leaflet */
@import '../../../node_modules/leaflet/dist/leaflet.css';
$background-app: #f0f8fa;

:root{
  box-sizing: border-box;
  font-size: 1rem; // browser base is 16px
  line-height: 1.25; // unitless, to force value recalculation in every element
  // max-width: 1400px;  // PRUEBA: ancho fijo 
  // margin: 0 auto;
}

*, ::before, ::after{
  box-sizing: inherit; // forces property to be inherited, taking into account using 3rd party components with different box-sizing (content-box)
}

body {
  font-family: getFontFamily('principal');
  background-color: $background-app;
  min-height: 100vh;
  height: auto;
}

div:where(.swal2-container) {
  z-index: 1200 !important;
}

div.clase-b{
  max-width: 1440px;  // PRUEBA: ancho fijo solamente página master
  margin: 0 auto;
}


