/* WIDTH, MIN-WIDTH, MAX-WIDTH */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: '';
  @if ($breakpoint_clave != '') {
    $modificador_responsive: '\\@#{$breakpoint_clave}';
  }
  @media (min-width: $breakpoint_valor) {
    @each $width_clave, $width_valor in $widthLista {
      .g-width-#{$width_clave}#{$modificador_responsive} {
        width: $width_valor;
      }
      .g-min-width-#{$width_clave}#{$modificador_responsive} {
        min-width: $width_valor;
      }
      .g-max-width-#{$width_clave}#{$modificador_responsive} {
        max-width: $width_valor;
      }
    }
  }
}
