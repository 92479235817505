@import "../../shared/utilities/mixins";
@import "../../shared/utilities/functions";

.badge {
    position: absolute;
    top: -5px;
    right: 0;
    padding: 0.1rem;
    padding-left: 0.375rem;
    padding-right: 0.25rem;
    @include border-radius('gd');
    background: getBackgrColor('alerta', 'error-icono');
    color: getTextColor('boton-blanco');
  }