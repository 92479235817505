/* BACKGROUND-COLOR */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
    $modificador_responsive: '';
    @if ($breakpoint_clave != '') {
      $modificador_responsive: '\\@#{$breakpoint_clave}';
    }
    @media (min-width: $breakpoint_valor) {
      .g-background-color-transparent#{$modificador_responsive} {
        background-color: transparent;
      }
      @each $componente, $variantes_color in $colores-backgroundLista {
        @each $clave_color, $valor_color in $variantes_color {
          .g-background-color-#{$componente}-#{$clave_color}#{$modificador_responsive} {
            background-color: $valor_color;
          }
        }
      }
    }
  }