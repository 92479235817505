/* FLEX */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: "";
  @if ($breakpoint_clave != "") {
    $modificador_responsive: "\\@#{$breakpoint_clave}";
  }
  @media (min-width: $breakpoint_valor) {
    .g-flex#{$modificador_responsive} {
      display: flex;
    }
    //Direction
    .g-flex--direction-row#{$modificador_responsive} {
      flex-direction: row;
    }
    .g-flex--direction-column#{$modificador_responsive} {
      flex-direction: column;
    }
    .g-flex--direction-row-reverse#{$modificador_responsive} {
      flex-direction: row-reverse;
    }
    .g-flex--direction-column-reverse#{$modificador_responsive} {
      flex-direction: column-reverse;
    }

    //Wrap
    .g-flex--wrap#{$modificador_responsive} {
      flex-wrap: wrap;
    }
    .g-flex--wrap-reverse#{$modificador_responsive} {
      flex-wrap: wrap-reverse;
    }
    .g-flex--nowrap#{$modificador_responsive} {
      flex-wrap: nowrap;
    }

    //Justify content
    .g-flex--justify-content-end#{$modificador_responsive} {
      justify-content: flex-end;
    }
    .g-flex--justify-content-start#{$modificador_responsive} {
      justify-content: flex-start;
    }
    .g-flex--justify-content-center#{$modificador_responsive} {
      justify-content: center;
    }
    .g-flex--justify-content-space-between#{$modificador_responsive} {
      justify-content: space-between;
    }
    .g-flex--justify-content-space-around#{$modificador_responsive} {
      justify-content: space-around;
    }
    .g-flex--justify-content-stretch#{$modificador_responsive} {
      justify-content: stretch;
    }

    //Justify self
    .g-flex--justify-self-stretch#{$modificador_responsive} {
      justify-self: stretch;
    }
    .g-flex--justify-self-end#{$modificador_responsive} {
      justify-self: flex-end;
    }
    .g-flex--justify-self-start#{$modificador_responsive} {
      justify-self: flex-start;
    }
    .g-flex--justify-self-center#{$modificador_responsive} {
      justify-self: center;
    }
    .g-flex--justify-self-baseline#{$modificador_responsive} {
      justify-self: baseline;
    }

    //Justify items
    .g-flex--justify-items-stretch#{$modificador_responsive} {
      justify-items: stretch;
    }
    .g-flex--justify-items-end#{$modificador_responsive} {
      justify-items: flex-end;
    }
    .g-flex--justify-items-start#{$modificador_responsive} {
      justify-items: flex-start;
    }
    .g-flex--justify-items-center#{$modificador_responsive} {
      justify-items: center;
    }
    .g-flex--justify-items-baseline#{$modificador_responsive} {
      justify-items: baseline;
    }

    //Align Items
    .g-flex--align-items-stretch#{$modificador_responsive} {
      align-items: stretch;
    }
    .g-flex--align-items-start#{$modificador_responsive} {
      align-items: flex-start;
    }
    .g-flex--align-items-end#{$modificador_responsive} {
      align-items: flex-end;
    }
    .g-flex--align-items-center#{$modificador_responsive} {
      align-items: center;
    }
    .g-flex--align-items-baseline#{$modificador_responsive} {
      align-items: baseline;
    }

    //Align self
    .g-flex--align-self-stretch#{$modificador_responsive} {
      align-self: stretch;
    }
    .g-flex--align-self-start#{$modificador_responsive} {
      align-self: flex-start;
    }
    .g-flex--align-self-end#{$modificador_responsive} {
      align-self: flex-end;
    }
    .g-flex--align-self-center#{$modificador_responsive} {
      align-self: center;
    }
    .g-flex--align-self-baseline#{$modificador_responsive} {
      align-self: baseline;
    }

    //Align content
    .g-flex--align-content-start#{$modificador_responsive} {
      align-content: flex-start;
    }
    .g-flex--align-content-end#{$modificador_responsive} {
      align-content: flex-end;
    }
    .g-flex--align-content-center#{$modificador_responsive} {
      align-content: center;
    }
    .g-flex--align-content-baseline#{$modificador_responsive} {
      align-content: baseline;
    }
    .g-flex--align-content-space-around#{$modificador_responsive} {
      align-content: space-around;
    }
    .g-flex--align-content-space-between#{$modificador_responsive} {
      align-content: space-between;
    }
    .g-flex--align-content-stretch#{$modificador_responsive} {
      align-content: stretch;
    }

    // GAP
    @each $gap_clave, $gap_valor in $gapLista {
      .g-flex--gap-#{$gap_clave}#{$modificador_responsive} {
        gap: $gap_valor;
      }
      // CHECK: los gap de row y column no se aplican al DOM
      .g-flex--gap-row-#{$gap_clave}#{$modificador_responsive} { 
        row-gap: $gap_valor;
      }
      .g-flex--gap-column-#{$gap_clave}#{$modificador_responsive} {
        column-gap: $gap_valor;
      }
    }

    // GROW
    @each $gap_valor in 0,1,2,3,4,5 {
      .g-flex--grow-#{$gap_valor}#{$modificador_responsive} {
        flex-grow: $gap_valor;
      }
    }
  }
}
