@use '../utilities/mixins';
/* TIPOGRAFIA */

@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: '';
  @if ($breakpoint_clave != '') {
    $modificador_responsive: '\\@#{$breakpoint_clave}';
  }
  @media (min-width: $breakpoint_valor) {
    @each $fuente_clave in map-keys($fuentesLista) {
      .g-font--#{$fuente_clave}#{$modificador_responsive} {
        @include mixins.tipografia($fuente_clave);
      }
      .g-font--#{$fuente_clave}-negrita#{$modificador_responsive} {
        @extend .g-font--#{$fuente_clave}#{$modificador_responsive};
        font-weight: 700;
      }
      .g-font--#{$fuente_clave}-cursiva#{$modificador_responsive} {
        @extend .g-font--#{$fuente_clave}#{$modificador_responsive};
        font-style: oblique;
      }
    }
  }
}
