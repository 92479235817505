/* HEIGHT, MIN-HEIGHT, MAX-HEIGHT */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
    $modificador_responsive: '';
    @if ($breakpoint_clave != '') {
      $modificador_responsive: '\\@#{$breakpoint_clave}';
    }
    @media (min-width: $breakpoint_valor) {
      @each $height_clave, $height_valor in $heightLista {
        .g-height-#{$height_clave}#{$modificador_responsive} {
          height: $height_valor;
        }
        .g-min-height-#{$height_clave}#{$modificador_responsive} {
          min-height: $height_valor;
        }
        .g-max-height-#{$height_clave}#{$modificador_responsive} {
          max-height: $height_valor;
        }
      }
    }
  }