/* OVERFLOW */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: "";
  @if ($breakpoint_clave != "") {
    $modificador_responsive: "\\@#{$breakpoint_clave}";
  }
  @media (min-width: $breakpoint_valor) {
      @each $overflow_clave, $overflow_valor in $overflowLista {
        .g-overflow-#{$overflow_clave}#{$modificador_responsive} {
          overflow: $overflow_valor;
        }
      }
    }
  }