/* POSITION */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: "";
  @if ($breakpoint_clave != "") {
    $modificador_responsive: "\\@#{$breakpoint_clave}";
  }
  @media (min-width: $breakpoint_valor) {
      @each $position_clave, $position_valor in $positionLista {
        .g-position-#{$position_clave}#{$modificador_responsive} {
          position: $position_valor;
        }
      }
    }
  }