/* TEXT-ALIGN */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: "";
  @if ($breakpoint_clave != "") {
    $modificador_responsive: "\\@#{$breakpoint_clave}";
  }
  @media (min-width: $breakpoint_valor) {
      @each $text-align_clave, $text-align_valor in $text-alignLista {
        .g-text-align-#{$text-align_clave}#{$modificador_responsive} {
          text-align: $text-align_valor;
        }
      }
    }
  }