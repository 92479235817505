/* VIEWPORT WIDTH */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: "";
  @if ($breakpoint_clave != "") {
    $modificador_responsive: "\\@#{$breakpoint_clave}";
  }
  @media (min-width: $breakpoint_valor) {
    @each $vw_clave, $vw_valor in $viewport-widthLista {
      .g-width-vw-#{$vw_clave}#{$modificador_responsive} {
        width: $vw_valor;
      }
    }
  }
}
/* VIEWPORT HEIGHT */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: "";
  @if ($breakpoint_clave != "") {
    $modificador_responsive: "\\@#{$breakpoint_clave}";
  }
  @media (min-width: $breakpoint_valor) {
    @each $vh_clave, $vh_valor in $viewport-heightLista {
      .g-height-vh-#{$vh_clave}#{$modificador_responsive} {
        height: $vh_valor;
      }
    }
  }
}
