/* PADDING */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
    $modificador_responsive: "";
    @if ($breakpoint_clave != "") {
      $modificador_responsive: "\\@#{$breakpoint_clave}";
    }
    @media (min-width: $breakpoint_valor) {
      @each $padding_clave, $padding_valor in $paddingLista {
        .g-padding-#{$padding_clave}#{$modificador_responsive} {
            padding: $padding_valor;
          }
          .g-padding-left-#{$padding_clave}#{$modificador_responsive} {
            padding-left: $padding_valor;
          }
          .g-padding-right-#{$padding_clave}#{$modificador_responsive} {
            padding-right: $padding_valor;
          }
          .g-padding-top-#{$padding_clave}#{$modificador_responsive} {
            padding-top: $padding_valor;
          }
          .g-padding-bottom-#{$padding_clave}#{$modificador_responsive} {
            padding-bottom: $padding_valor;
          }
          .g-padding-horizontal-#{$padding_clave}#{$modificador_responsive} {
            padding-left: $padding_valor;
            padding-right: $padding_valor;
          }
          .g-padding-vertical-#{$padding_clave}#{$modificador_responsive} {
            padding-top: $padding_valor;
            padding-bottom: $padding_valor;
          }
      }
    }
  }