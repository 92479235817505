/* DISPLAY */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
    $modificador_responsive: '';
    @if ($breakpoint_clave != '') {
      $modificador_responsive: '\\@#{$breakpoint_clave}';
    }
    @media (min-width: $breakpoint_valor) {
      @each $display_clave, $display_valor in $displayLista {
        .g-display-#{$display_clave}#{$modificador_responsive} {
          display: $display_valor;
        }
      }
    }
  }