@import './variables';
//BOTON DESACTIVADO
@mixin boton-deshabilitado() {
  @include color('boton-primario-desactivado');
  @include border-color('primario-desactivado');
  @include background-color('boton', 'primario-activo');
  cursor: not-allowed;
}
@mixin boton-deshabilitado-sin-borde() {
  @include color('boton-primario-desactivado');
  @include background-color('boton', 'primario-activo');
  cursor: not-allowed;
}

@mixin boton-cuadrado-deshabilitado() {
  // @include color('boton-primario-desactivado');
  cursor: not-allowed;
}
//TIPOGRAFIA
@mixin tipografia($fuente_clave, $isImportant: false) {
  $fuente_valores: map-get($fuentesLista, $fuente_clave);
  $listLength: length($fuente_valores);
  $valor: '';
  @if ($isImportant) {
    $valor: '!important';
  }
  font-size: nth($fuente_valores, 1) unquote($valor);
  line-height: nth($fuente_valores, 2) unquote($valor);
  font-weight: nth($fuente_valores, 3) unquote($valor);
  font-style: nth($fuente_valores, 4) unquote($valor);
  letter-spacing: nth($fuente_valores, 5) unquote($valor);
  @if $listLength == 6 {
    text-transform: nth($fuente_valores, 6) unquote($valor);
  } @else {
    text-transform: none;
  }
}
//SOMBRA
//INFO: se utiliza para 1 o mas sombras aplicadas a la vez.
@mixin box-shadow($clave_sombra, $isImportant: false) {
  $sombra_valores: '';
  $sombra: map-get($sombrasLista, $clave_sombra);
  //averiguamos si $sombra es un array de 1 o 2 dimensiones.
  @if type-of(nth($sombra, 1)) == string {
    $sombra_valores: unquote(nth($sombra, 1)) + ' ' + nth($sombra, 2);
  } @else {
    $indiceArray: 1;
    $longitudArray: length($sombra);
    @each $sombra_valor in $sombra {
      $sombra_valores: append(
        unquote($sombra_valores),
        unquote(nth($sombra_valor, 1)) + ' ' + nth($sombra_valor, 2)
      );
      @if $longitudArray != $indiceArray {
        $sombra_valores: unquote($sombra_valores + ', ');
      }
      $indiceArray: $indiceArray + 1;
    }
  }
  $valor: '';
  @if ($isImportant) {
    $valor: '!important';
  }
  -webkit-box-shadow: $sombra_valores unquote($valor);
  -moz-box-shadow: $sombra_valores unquote($valor);
  box-shadow: $sombra_valores unquote($valor);
}

@mixin box-shadowNone($isImportant: false) {
  $valor: '';
  @if ($isImportant) {
    $valor: '!important';
  }
  -webkit-box-shadow: none unquote($valor);
  -moz-box-shadow: none unquote($valor);
  box-shadow: none unquote($valor);
}

//BORDER-RADIUS
@mixin border-radius($clave-radius, $isImportant: false) {
  $valor_radius: map-get($border-radiusLista, $clave_radius);

  $valor: '';
  @if ($isImportant) {
    $valor: '!important';
  }
  -webkit-border-radius: $valor_radius unquote($valor);
  -moz-border-radius: $valor_radius unquote($valor);
  border-radius: $valor_radius unquote($valor);
}

//COLOR
//tipoComponente = parrafo, boton, icono, etc...
@mixin background-color($tipoComponente, $clave_color, $isImportant: false) {
  $variantesColorPorTipo: map-get($colores-backgroundLista, $tipoComponente);
  $valor: '';
  @if ($isImportant) {
    $valor: '!important';
  }
  background-color: map-get($variantesColorPorTipo, $clave_color) unquote($valor);
}

@mixin border-color($clave_color, $isImportant: false) {
  $valor: '';
  @if ($isImportant) {
    $valor: '!important';
  }
  border-color: map-get($colores-bordeLista, $clave_color) unquote($valor);
}

@mixin color($clave_color, $isImportant: false) {
  $valor: '';
  @if ($isImportant) {
    $valor: '!important';
  }
  color: map-get($colores-textoLista, $clave_color) unquote($valor);
}
