/* GRID */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: "";
  @if ($breakpoint_clave != "") {
    $modificador_responsive: "\\@#{$breakpoint_clave}";
  }
  @media (min-width: $breakpoint_valor) {
    .g-grid#{$modificador_responsive} {
      display: grid;
    }
    //Rows & Columns
    @for $i from 1 to 6 {
      .g-grid--columns-#{$i}#{$modificador_responsive} {
        grid-template-columns: repeat($i, 1fr);
      }
      .g-grid--rows-#{$i}#{$modificador_responsive} {
        grid-template-rows: repeat($i, 1fr);
      }
    }
    //Align items
    .g-grid--align-items-start#{$modificador_responsive} {
      align-items: start;
    }
    .g-grid--align-items-end#{$modificador_responsive} {
      align-items: end;
    }
    .g-grid--align-items-center#{$modificador_responsive} {
      align-items: center;
    }
    .g-grid--align-items-stretch#{$modificador_responsive} {
      align-items: stretch;
    }
    //Justify content
    .g-grid--justify-content-end#{$modificador_responsive} {
      justify-content: end;
    }
    .g-grid--justify-content-start#{$modificador_responsive} {
      justify-content: start;
    }
    .g-grid--justify-content-center#{$modificador_responsive} {
      justify-content: center;
    }
    .g-grid--justify-content-space-between#{$modificador_responsive} {
      justify-content: space-between;
    }
    .g-grid--justify-content-space-around#{$modificador_responsive} {
      justify-content: space-around;
    }
    .g-grid--justify-content-stretch#{$modificador_responsive} {
      justify-content: stretch;
    }
    //Align content
    .g-grid--align-content-end#{$modificador_responsive} {
      align-content: end;
    }
    .g-grid--align-content-start#{$modificador_responsive} {
      align-content: start;
    }
    .g-grid--align-content-center#{$modificador_responsive} {
      align-content: center;
    }
    .g-grid--align-content-space-between#{$modificador_responsive} {
      align-content: space-between;
    }
    .g-grid--align-content-space-around#{$modificador_responsive} {
      align-content: space-around;
    }
    .g-grid--align-content-stretch#{$modificador_responsive} {
      align-content: stretch;
    }
    //Auto flow
    .g-grid--auto-flow-row#{$modificador_responsive} {
      grid-auto-flow: end;
    }
    .g-grid--auto-flow-column#{$modificador_responsive} {
      grid-auto-flow: column;
    }
    .g-grid--auto-flow-dense#{$modificador_responsive} {
      grid-auto-flow: dense;
    }
    //Justify self
    .g-grid--justify-self-end#{$modificador_responsive} {
      justify-self: end;
    }
    .g-grid--justify-self-start#{$modificador_responsive} {
      justify-self: start;
    }
    .g-grid--justify-self-center#{$modificador_responsive} {
      justify-self: center;
    }
    .g-grid--justify-self-stretch#{$modificador_responsive} {
      justify-self: stretch;
    }
    //Align self
    .g-grid--align-self-end#{$modificador_responsive} {
      align-self: end;
    }
    .g-grid--align-self-start#{$modificador_responsive} {
      align-self: start;
    }
    .g-grid--align-self-center#{$modificador_responsive} {
      align-self: center;
    }
    .g-grid--align-self-stretch#{$modificador_responsive} {
      align-self: stretch;
    }

    @each $gap_clave, $gap_valor in $gapLista {
      .g-grid--gap-#{$gap_clave}#{$modificador_responsive} {
        gap: $gap_valor;
      }
      .g-grid--gap-row-#{$gap_clave}#{$modificador_responsive} {
        row-gap: $gap_valor;
      }
      .g-grid--gap-column-#{$gap_clave}#{$modificador_responsive} {
        column-gap: $gap_valor;
      }
    }
  }
}
