@import '../utilities/functions';
@import '../utilities/variables';

//#region breakpoint
$xp: getBreakpoint('xp');
$pq: getBreakpoint('pq');
$md: getBreakpoint('md');
$gd: getBreakpoint('gd');
$xg: getBreakpoint('xg');

$breakpoints: (
  'pq': $pq,
  'md': $md,
  'gd': $gd,
  'xg': $xg,
);
//#endregion

//#region porcentajes
$porcentajes: (
  'auto': auto,
  '1': 10%,
  '2': 20%,
  '3': 30%,
  '4': 40%,
  '5': 50%,
  '6': 60%,
  '7': 70%,
  '8': 80%,
  '9': 90%,
  '10': 100%,
);
//#endregion

$columnas: (
  'auto': auto,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5
);

.container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.contenedor {
  container: contenedor / inline-size;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  flex-wrap: wrap;
}

@each $nombre, $porcentaje in $porcentajes {
  .row-#{$nombre} {
    display: flex;
    flex-direction: row;
    width: $porcentaje;
    flex-wrap: wrap;
  }

  .col-#{$nombre} {
    flex: 0 0 auto;
    width: $porcentaje;
  }
}

@each $nombre, $numero in $columnas {
  .row-cols-#{$nombre}>* {
    flex: 0 0 auto;
    width: calc(100%/$numero);
  }
}

@each $nombre, $numero in $columnas {
  @each $nombreGap, $numeroGap in $gapLista {
    .row-cols-gap-#{$nombreGap}-#{$nombre}>* {
      flex: 0 0 auto;
      width: calc(100%/$numero - $numeroGap);
    }
  }
}

//#region breakpoint
@each $break, $breakpoint in $breakpoints {
  @media (min-width: $breakpoint) {
    .col-#{$break} {
      display: flex;
      flex-direction: column;
      flex: 1 0 0%;
      flex-wrap: wrap;
    }

    .row-#{$break} {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      flex: none;
    }

    @each $nombre, $porcentaje in $porcentajes {
      .col-#{$break}-#{$nombre} {
        width: $porcentaje;
      }
    }

    @each $nombre, $numero in $columnas {
      .row-#{$break}-cols-#{$nombre}>* {
        flex: 0 0 auto;
        width: calc(100%/$numero);
      }
    }

    @each $nombre, $numero in $columnas {
      @each $nombreGap, $numeroGap in $gapLista {
        .row-#{$break}-cols-gap-#{$nombreGap}-#{$nombre}>* {
          flex: 0 0 auto;
          width: calc(100%/$numero - $numeroGap);
        }
      }
    }
  }
}

//#endregion

//#region breakpoint contenedor
@container contenedor (min-width: #{$pq}) {
  .col-container-pq {
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    flex-wrap: wrap;
  }

  .row-container-pq {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    flex: none;
  }

  @each $nombre, $porcentaje in $porcentajes {
    .col-container-pq-#{$nombre} {
      width: $porcentaje;
    }
  }
}

@container contenedor (min-width: #{$md}) {
  .col-container-md {
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    flex-wrap: wrap;
  }

  .row-container-md {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    flex: none;
  }

  @each $nombre, $porcentaje in $porcentajes {
    .col-container-md-#{$nombre} {
      width: $porcentaje;
    }
  }
}

@container contenedor (min-width: #{$gd}) {
  .col-container-gd {
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    flex-wrap: wrap;
  }

  .row-container-gd {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    flex: none;
  }

  @each $nombre, $porcentaje in $porcentajes {
    .col-container-gd-#{$nombre} {
      width: $porcentaje;
    }
  }
}

@container contenedor (min-width: #{$xg}) {
  .col-container-xg {
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    flex-wrap: wrap;
  }

  .row-container-xg {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    flex: none;
  }

  @each $nombre, $porcentaje in $porcentajes {
    .col-container-xg-#{$nombre} {
      width: $porcentaje;
    }
  }
}

//#endregion
