//ESPACIADO

$heightLista:  ("auto":auto, "0":0, "2":0.125rem, "4":0.25rem, "6":0.375rem, "xxp":0.5rem, "10":0.625rem, "12":0.75rem, "xp":1rem, "md":1.5rem, "gd":2rem, "xg":2.5rem, "xxg":3rem, "64":4rem, "72":4.5rem, "80":5rem, "96":6rem, "112":7rem, "128":8rem, "144":9rem, "152": 9.5rem, "160": 10rem, "176": 11rem, "192":12rem, "512": 32rem, "10perc": 10%, "15perc":15%, "18perc": 18%, "50perc":50%, "90perc": 90%, "fill": 100%, "100vh": 100vh);
$widthLista:   ("auto":auto, "0":0, "2":0.125rem, "4":0.25rem, "6":0.375rem, "xxp":0.5rem, "10":0.625rem, "12":0.75rem, "xp":1rem, "md":1.5rem, "gd":2rem, "xg":2.5rem, "xxg":3rem, "64":4rem, "80":5rem, "96":6rem, "112":7rem, "128":8rem, "144":9rem, "152": 9.5rem, "160": 10rem, "192":12rem, "512": 32rem, "10perc": 10%, "15perc":15%, "18perc": 18%, "50perc":50%, "70perc":70%, "90perc": 90%, "fill": 100%, "100vw": 100vw);
$marginLista:  ("auto":auto, "0":0, "2":0.125rem, "4":0.25rem, "6":0.375rem, "xxp":0.5rem, "10":0.625rem, "12":0.75rem, "xp":1rem, "md":1.5rem, "gd":2rem, "xg":2.5rem, "xxg":3rem, "64":4rem, "80":5rem, "96":6rem, "112":7rem, "128":8rem, "160":10rem);
$paddingLista: ("0":0, "2":0.125rem, "4":0.25rem, "6":0.375rem, "xxp":0.5rem, "10":0.625rem, "12":0.75rem, "xp":1rem, "md":1.5rem, "gd":2rem, "xg":2.5rem, "xxg":3rem, "64":4rem, "80":5rem, "96":6rem, "112":7rem, "128":8rem);
$viewport-widthLista:("10": 10vw,"20": 20vw,"30": 30vw,"40": 40vw,"50": 50vw,"60": 60vw,"70": 70vw,"80": 80vw,"90": 90vw,"100": 100vw);
$viewport-heightLista:("10": 10vh,"20": 20vh,"30": 30vh,"40": 40vh,"50": 50vh,"60": 60vh,"70": 70vh,"80": 80vh,"90": 90vh,"100": 100vh);
$gapLista: ("2": 0.125rem,"4":0.25rem, "6":0.375rem, "xxp": 0.5rem,"xp": 1rem,"md": 1.5rem,"gd": 2rem,"xg": 2.5rem,"xxg": 3rem);

//BREAKPOINTS DESTINUX WEB

$breakpointLista: ("": 0px, "pq": 576px, "md": 768px, "gd": 1200px, "xg": 1400px);

//POSICIONAMIENTO

$positionLista:("absolute":absolute, "relative":relative, "fixed":fixed, "sticky": sticky, "unset": unset);
$z-indexLista:("auto":auto, "neg-1":-1, "0":0, "1":1, "2":2, "3":3, "4":4, "5":5, "6":6, "7":7, "975":975, "999":999, "1002": 1002,"1020":1020, "1200":1200,"1250":1250, "9999":9999);
$displayLista: ("block": block, "inline": inline, "none": none, "inline-block": inline-block);

//MOSTRAR EN VISTA

$opacityLista:("0": 0, "20":0.2, "40":0.4, "60":0.6, "80":0.8, "100":1.0);
$overflowLista: ("visible":visible, "hidden":hidden, "scroll":scroll, "auto":auto, "inherit":inherit);
$visibilidadLista: ("visible": visible, "no-visible": hidden );

//TEXTOS

$text-alignLista: ("center":center, "start":start, "end":end, "left":left, "right":right, "justify":justify, "initial":initial, "inherit":inherit);
$font-familyLista: ("principal": 'Kumbh Sans', "secundaria": 'Rubik');

// tamanyo, interlineado, peso, estilo, espaciado, mayusculas(opcional)
$fuentesLista: (//titulos
                "titulo-xxg": (2.625rem, 2.875rem, 200, normal, 0rem),
                "titulo-xg": (2.375rem, 2.875rem, 200, normal, 0.25rem),
                "titulo-gd": (2rem, 2.5rem, 300, normal, 0.0625rem),
                "titulo-md": (1.5rem, 1.875rem, 300, normal, 0.0625rem),
                "titulo-pq": (1rem, 1.25rem, 500, normal, 0.0625rem),
                "titulo-xp": (0.75rem, 1rem, 600, normal, 0.0625rem),
                //subtitulos
                "subtitulo-gd": (1.75rem, 2.125rem, 600, normal, 0.125rem),
                "subtitulo-md": (0.75rem, 1rem, 600, normal, 0.0625rem),
                "subtitulo-pq": (0.625rem, 0.75rem, 600, normal, 0.0625rem),
                "subtitulo-xp": (0.5rem, 0.625rem, 600, normal, 0.0625rem),
                "subtitulo-xxp": (0.4375rem, 0.5625rem, 600, normal, 0.0625rem),
                //botones
                "boton-xg": (1.25rem, 1.625rem, 400, normal, 0.125rem, uppercase),
                "boton-gd": (1rem, 1.5rem, 400, normal, 0.125rem, uppercase),
                "boton-md": (0.75rem, 1rem, 400, normal, 0.125rem, uppercase),
                "boton-pq": (0.625rem, 0.875rem, 400, normal, 0.0625rem, uppercase),
                "boton-xp": (0.5rem, 0.625rem, 400, normal, 0.0625rem, uppercase),
                //menu
                "menu-gd": (0.75rem, 0.875rem, 400, normal, 0rem, uppercase),
                "menu-md": (0.5rem, 0.75rem, 600, normal, 0rem, uppercase),
                "menu-pq": (0.375rem, 0.5rem, 600, normal, 0rem, uppercase),
                //parrafo
                "parrafo-gd": (1.25rem, 1.625rem, 300, normal, 0rem),
                "parrafo-md": (1rem, 1.5rem, 300, normal, 0rem),
                "parrafo-pq": (0.875rem, 1.125rem, 400, normal, 0rem),
                "parrafo-xp": (0.75rem, 0.875rem, 400, normal, 0rem),
                "parrafo-xpq": (0.625rem, 0.75rem, 400, normal, 0rem),
                "parrafo-xxp": (0.5rem, 0.625rem, 400, normal, 0rem),
                //numero
                "precio-xg": (2.375rem, 2.75rem, 600, normal, 0rem),
                "precio-gd": (1.75rem, 2rem, 600, normal, 0rem),
                "precio-md": (1.25rem, 1.5rem, 600, normal, 0rem),
                "precio-pq": (0.875rem, 1.125rem, 600, normal, 0rem),
                "precio-xp": (0.75rem, 0.875rem, 400, normal, 0rem),
                "cifra-md": (1rem, 1.5rem, 600, normal, 0rem),
                "porcentaje-pq": (1rem, 1.5rem, 400, normal, 0rem),
                "fecha-pq": (1rem, 1.25rem, 300, normal, 0rem),
                "fecha-md": (1.5rem, 1.75rem, 300, normal, 0rem),
                "fecha-gd": (2.5rem, 3rem, 300, normal, 0rem),
                "fecha-xp": (0.75rem, 0.75rem, 300, normal, 0rem),
                "calendario-pq": (1rem, 1.5rem, 300, normal, 0rem));

//PALETAS DE COLORES

$colores-backgroundLista: (
  "base": ( "oscuro":#00223a, 
            "claro":#ffffff, 
            "claro-50":#ffffff80, 
            "fondo-primario": #f0f8fa,
            "fondo-secundario": #f9fcfd,
            "cuadro-informativo": #c4daec,
            "fondo-terciario": #e5f5ff),  
  "icono": ("primario":#9bb1c4, 
            "secundario":#726eef,
            "desactivado":#dbe8f4, 
            "claro":#ffffff), 
  "boton": ("primario-activo":#ffffff, 
            "primario-enfocado":#eaf1f8, 
            "secundario-activo":#84b1d9, 
            "secundario-enfocado":#6a8eae,
            "terciario-activo":#007bc0,
            "terciario-enfocado":#e2edf6, 
            "info-activo":#005fce,
            "info-enfocado":#004ca5,
            "destaque-activo":#726eef,
            "destaque-enfocado":#5a56cc, 
            "exito-activo":#0fb5ae,
            "exito-enfocado":#0a746f,
            "advertencia-activo":#fbc401,
            "advertencia-enfocado":#c99d01, 
            "peligro-activo":#ff3055,
            "peligro-enfocado":#cc2644),
  "alerta":("info-fondo":#dff2ff,            
            "info-icono":#005fce,
            "error-fondo":#fef2f2,            
            "error-icono":#ff3055,
            "advertencia-fondo":#fff7ed, 
            "alerta-200":#fff2c6,        
            "advertencia-icono":#fbc401,
            "exito-fondo":#f0fdf4,
            "exito-icono":#0fb5ae),
  "grafica":("azul-top":#78b0ffcc,
            "verde-top":#7acdbf,
            "base":#d1e2f3)          
);

$colores-bordeLista:("primario":#e2edf6,
                    "blanco":#ffffff,
                    "switch-activo":#9bb1c4,
                    "primario-desactivado":#dbe8f4,
                    "delineado":#84b1d9,
                    "delineado-enfocado":#6a8eae,
                    "tarifa-incumple":#f3a368, 
                    "advertencia":#fbc401,
                    "advertencia-enfocado":#c99d01,
                    "info":#005fce,
                    "info-enfocado":#004ca5,
                    "exito":#0fb5ae,
                    "exito-500":#0c918b,
                    "exito-enfocado":#0a746f,
                    "error":#ff3055,
                    "error-enfocado":#cc2644,
                    "destaque":#726eef,
                    "destaque-enfocado-400":#9997f4,
                    "destaque-enfocado":#5a56cc,
                    "formulario-enfocado": #9997f4);

$colores-textoLista: ("parrafo":#4f5e71,
                      "titulo":#00528a,
                      "subtitulo":#3375a1,
                      "enlace":#726eef,
                      "form-placeholder":#84b1d9,
                      "boton-primario":#00223a,
                      "boton-primario-enfocado":#6a8eae,
                      "boton-primario-desactivado":#dbe8f4,
                      //INFO: texto boton-blanco rellenado: para secundario, error, info, destaque y exito
                      "boton-blanco":#ffffff,
                      "boton-advertencia":#745a00,
                      "boton-advertencia-activo-variacion-texto":#c99d01,
                      "boton-info":#005fce,
                      "boton-info-enfocado":#004ca5, 
                      "boton-error":#ff3055,
                      "boton-error-enfocado":#cc2644, 
                      "boton-exito-400":#01bb9c, 
                      "boton-exito":#0fb5ae, 
                      "boton-exito-enfocado":#0a746f,
                      "boton-secundario":#84b1d9,
                      "boton-secundario-enfocado":#6a8eae, 
                      "boton-destaque":#726eef,
                      "boton-destaque-enfocado":#5a56cc,
                      "alerta-error":#cc2644,
                      "alerta-info":#004ca5,
                      "alerta-advertencia":#745a00, 
                      "alerta-exito":#0a746f,
                      "opcion-multiselect":#146094,
                      "tarifa-destinux":#4177f6); 
                      
//SOMBRAS

$sombrasLista: ("gd": ("0rem 0.625rem 1.25rem 0rem", #5c78991a),
                "md": ("0rem 0.3125rem 1rem 0rem", #1a4a810d),
                "pq": ("0rem 0.125rem 0.625rem 0rem", #5c78994d),
                "activo": ("0rem 0.3125rem 1rem 0rem", #5c789933),
                "seleccionado": ("0rem 0.625rem 1.25rem 0rem", #726eef33),
                "campo-error": ("0rem 0.3125rem 0.875rem 0rem", #ff000026),
                "campo-enfocado": ("0rem 0.1875rem 0.625rem 0rem", #726eef80),
                "tarjeta-enfocada": ("0rem 0.3125rem 1rem 0rem", #726eef26),
                "boton-enfocado": (("0rem 0rem 0.25rem 0rem", #726eef33),("0rem 0.625rem 1.25rem 0rem", #726eef33))
                );

//TAMAÑOS DE ICONOS

$svg-iconHeightPxLista: ("xp":12px, "pq":16px, "md":24px, "gd":32px, "xg":40px, "fill":100%); // tbd
$svg-iconWidthPxLista: ("xp":12px, "pq":16px, "md":24px, "gd":32px, "xg":40px, "fill":100%);

//TIPOS DE CURSOR

$cursorLista: ("default": default, "none": none, "pointer": pointer, "crosshair": crosshair, "move": move, "grab": grab, "text": text, "not-allowed": not-allowed, "zoom-in": zoom-in, "zoom-out": zoom-out);

//BORDES

$border-radiusLista:("4":0.25rem, "xxp":0.5rem, "12":0.75rem, "xp":1rem, "md":1.5rem, "gd": 2rem, "xg": 3.125rem);
