@import '../utilities/mixins';
@import '../utilities/functions';

.c-boton {
  cursor: pointer;
  user-select: none;
  border: none;
  position: relative;

  .c-boton__texto {
    text-wrap: balance;
  }

  &--normal,
  &--icono,
  &--imagen {
    &-relleno {
      &-primario {
        @include background-color('boton', 'primario-activo');
        @include color('boton-primario');
        border: solid 0.0625rem getBorderColor('primario');
        @include box-shadow('activo');

        &:hover {
          @include background-color('boton', 'primario-enfocado');
          @include border-color('destaque');
          @include box-shadow('boton-enfocado');
        }
      }

      &-secundario {
        @include background-color('boton', 'secundario-activo');
        @include color('boton-blanco');
        @include box-shadow('activo');

        &:hover {
          @include background-color('boton', 'secundario-enfocado');
          @include box-shadow('boton-enfocado');
        }
      }

      &-advertencia {
        @include background-color('boton', 'advertencia-activo');
        @include color('boton-advertencia');
        @include box-shadow('activo');

        &:hover {
          @include background-color('boton', 'advertencia-enfocado');
          @include box-shadow('boton-enfocado');
        }
      }

      &-info {
        @include background-color('boton', 'info-activo');
        @include color('boton-blanco');
        @include box-shadow('activo');

        &:hover {
          @include background-color('boton', 'info-enfocado');
          @include box-shadow('boton-enfocado');
        }
      }

      &-error {
        @include background-color('boton', 'peligro-activo');
        @include color('boton-blanco');
        @include box-shadow('activo');

        &:hover {
          @include background-color('boton', 'peligro-enfocado');
          @include box-shadow('boton-enfocado');
        }
      }

      &-exito {
        @include background-color('boton', 'exito-activo');
        @include color('boton-blanco');
        @include box-shadow('activo');

        &:hover {
          @include background-color('boton', 'exito-enfocado');
          @include box-shadow('boton-enfocado');
        }
      }

      &-destaque {
        @include background-color('boton', 'destaque-activo');
        @include color('boton-blanco');
        @include box-shadow('activo');

        &:hover {
          @include background-color('boton', 'destaque-enfocado');
          @include box-shadow('boton-enfocado');
        }
      }
    }

    &-delineado {
      &-primario {
        background-color: transparent;
        border: solid 1px getBorderColor('delineado');
        @include color('boton-primario');

        &:hover {
          @include border-color('delineado-enfocado');
        }
      }

      &-secundario {        
        background-color: transparent;
        border: solid 1px getBorderColor('delineado');
        @include color('boton-secundario');

        &:hover {
          @include border-color('delineado-enfocado');
        }
      }

      &-advertencia {
        background-color: transparent;
        border: solid 1px getBorderColor('advertencia');
        @include color('boton-advertencia');

        &:hover {
          @include border-color('advertencia-enfocado');
        }
      }

      &-info {
        background-color: transparent;
        border: solid 1px getBorderColor('info');
        @include color('boton-info');

        &:hover {
          @include border-color('info-enfocado');
        }
      }

      &-error {
        background-color: transparent;
        border: solid 1px getBorderColor('error');
        @include color('boton-error');

        &:hover {
          @include border-color('error-enfocado');
        }
      }

      &-exito {
        background-color: transparent;
        border: solid 1px getBorderColor('exito');
        @include color('boton-exito');

        &:hover {
          @include border-color('exito-enfocado');
        }
      }

      &-destaque {
        background-color: transparent;
        border: solid 1px getBorderColor('destaque');
        @include color('boton-destaque');

        &:hover {
          @include border-color('destaque-enfocado');
        }
      }

      &-negativo {
        background-color: transparent;
        border: solid 1px getBorderColor('blanco');
        @include color('boton-blanco');
      }
    }

    &-limpio {
      &-primario {
        background-color: transparent;
        @include color('boton-primario');        

        &:hover {
          @include color('boton-primario-enfocado');          
        }
      }

      &-secundario {
        background-color: transparent;
        @include color('boton-secundario');        

        &:hover {
          @include color('boton-secundario-enfocado');
        }
      }

      &-advertencia {
        background-color: transparent;
        @include color('boton-advertencia-activo-variacion-texto');

        &:hover {
          @include color('boton-advertencia');
        }
      }

      &-info {
        background-color: transparent;
        @include color('boton-info');

        &:hover {
          @include color('boton-info-enfocado');
        }
      }

      &-error {
        background-color: transparent;
        @include color('boton-error');

        &:hover {
          @include color('boton-error-enfocado');
        }
      }

      &-exito {
        background-color: transparent;
        @include color('boton-exito');

        &:hover {
          @include color('boton-exito-enfocado');
        }
      }

      &-destaque {
        background-color: transparent;
        @include color('boton-destaque');

        &:hover {
          @include color('boton-destaque-enfocado');
        }
      }
    }
  }

  .height-gd{
    height: 3.5rem;
  }

  .width-gd{
    width: 3.5rem;
  }
  
  &--desactivado {
    @include box-shadowNone;
    @include boton-deshabilitado();
    &-sin-borde {
      @include box-shadowNone;
      @include boton-deshabilitado-sin-borde();
    }
  }

  //AUXILIAR
  &--auxiliar {
    position: relative;
    height: 3rem;
    @include background-color('boton', 'primario-activo');
    border: solid 0.0625rem getBorderColor('primario');
    @include border-radius('md');
    @include color('parrafo');
    gap: getGap('xp');
    @include tipografia('subtitulo-md');
    @include box-shadow('gd');
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      @include box-shadow('seleccionado');
      @include background-color('boton', 'primario-enfocado');
      @include color('titulo');
      outline: none;
    }
  }
}
