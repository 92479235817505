/* MARGIN */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
    $modificador_responsive: "";
    @if ($breakpoint_clave != "") {
      $modificador_responsive: "\\@#{$breakpoint_clave}";
    }
    @media (min-width: $breakpoint_valor) {
      @each $margin_clave, $margin_valor in $marginLista {
        .g-margin-#{$margin_clave}#{$modificador_responsive} {
            margin: $margin_valor;
          }
          .g-margin-left-#{$margin_clave}#{$modificador_responsive} {
            margin-left: $margin_valor;
          }
          .g-margin-right-#{$margin_clave}#{$modificador_responsive} {
            margin-right: $margin_valor;
          }
          .g-margin-top-#{$margin_clave}#{$modificador_responsive} {
            margin-top: $margin_valor;
          }
          .g-margin-bottom-#{$margin_clave}#{$modificador_responsive} {
            margin-bottom: $margin_valor;
          }
          .g-margin-horizontal-#{$margin_clave}#{$modificador_responsive} {
            margin-left: $margin_valor;
            margin-right: $margin_valor;
          }
          .g-margin-vertical-#{$margin_clave}#{$modificador_responsive} {
            margin-top: $margin_valor;
            margin-bottom: $margin_valor;
          }
      }
    }
  }
  