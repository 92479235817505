/* VISIBILITY */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
    $modificador_responsive: '';
    @if ($breakpoint_clave != '') {
      $modificador_responsive: '\\@#{$breakpoint_clave}';
    }
    @media (min-width: $breakpoint_valor) {
      @each $visibilidad_clave, $visibilidad_valor in $visibilidadLista {
        .g-visibility-#{$visibilidad_clave}#{$modificador_responsive} {
          visibility: $visibilidad_valor;
        }
      }
    }
  }