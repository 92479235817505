/* CURSOR */
@each $breakpoint_clave, $breakpoint_valor in $breakpointLista {
  $modificador_responsive: '';
  @if ($breakpoint_clave != '') {
    $modificador_responsive: '\\@#{$breakpoint_clave}';
  }
  @media (min-width: $breakpoint_valor) {
    @each $cursor_clave, $cursor_valor in $cursorLista {
      .g-cursor-#{$cursor_clave}#{$modificador_responsive} {
        cursor: $cursor_valor;
      }
    }
  }
}